import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { TextValidator } from 'react-material-ui-form-validator';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import Layout from '../layout/Layout';
import ErrorSnackbar from '../common/ErrorSnackbar';
import ValidatorForm from '../common/ValidatorForm';

import {
  selectQR,
  selectIsLoading,
  selectError,
} from '../../store/selectors/authentication';
import { finishInstallation as finishInstallationThunk } from '../../store/thunks/authentication';

class SetupMFA extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      authCode: '',
    };
  }

  handleFinishRegistraion = () => {
    const { finishInstallation, token } = this.props;
    const { authCode } = this.state;
    finishInstallation(token, authCode);
  };

  handleAuthCodeChange = ({ target: { value } }) => this.setState({ authCode: value });

  render() {
    const { qr, isLoading, error, buttonLabel, mainTitle } =
      this.props;
    const { authCode } = this.state;
    return (
      <Layout withTitle={false} withBreadcrumbs={false} withSliderMenu={false}>
        <div className="container">
          <Paper
            sx={(theme) => ({
              maxWidth: '600px',
              marginLeft: 'auto',
              marginRight: 'auto',
              marginTop: theme.spacing(1),
              marginBottom: theme.spacing(1),
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(
                3
              )}`,
            })}
            elevation={2}
          >
            <Typography
              component="h1"
              variant="h4"
              className="mt-3 mb-1"
              align="center"
            >
              <CheckCircleIcon
                className="green iconLeft iconLarge"
                style={{ marginBottom: '-9px', marginLeft: '-9px' }}
              />
              {mainTitle}
            </Typography>
            <Typography
              component="h2"
              variant="h5"
              className="mx-4"
              align="center"
            >
              The account is almost ready to use.
            </Typography>
            <Grid
              container
              spacing={2}
              className="loginActions mt-3 justify-end"
            >
              <Grid item xs={12} sm={6}>
                <Typography className="mb-1">
                  Use an application on your phone to get two-factor
                  authentication codes when prompted.
                </Typography>
                <Typography className="mb-1">
                  We recommend using an application such as{' '}
                  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en">
                    Google Authenticator
                  </a>{' '}
                  or <a href="https://authy.com/download/">Authy</a>. These
                  applications support secure backup of your authentication
                  codes in the cloud and can be restored if you lose access to
                  your device.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="qr">
                  <svg viewBox={qr.qrViewBox}>
                    <path d={qr.qrPathD} />
                    Sorry, your browser does not support inline SVG.
                  </svg>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              className="loginActions justify-end align-end"
            >
              <Grid item xs={12} sm={6}>
                <ValidatorForm
                  onSubmit={this.handleFinishRegistraion}
                  instantValidate={false}
                >
                  <TextValidator
                    id="authCode"
                    label="Enter the authentication code"
                    onChange={(e) => this.handleAuthCodeChange(e)}
                    name="authCode"
                    value={authCode}
                    margin="normal"
                    variant="standard"
                    fullWidth
                    validators={['required']}
                    errorMessages={['This field is required']}
                    className="mb-2"
                    helperText="Scan the image above with the two-factor authentication app on your phone."
                  />
                </ValidatorForm>
                <ErrorSnackbar error={error} className="authError mb-2 mt-1" />
                <Button
                  type="submit"
                  color="primary"
                  className="loginButton"
                  onClick={() => this.handleFinishRegistraion()}
                  disabled={isLoading}
                >
                  {buttonLabel}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </div>
      </Layout>
    );
  }
}

SetupMFA.propTypes = {
  qr: PropTypes.string,
  isLoading: PropTypes.bool,
  error: PropTypes.string,
  buttonLabel: PropTypes.string,
  mainTitle: PropTypes.string,
  finishInstallation: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

SetupMFA.defaultProps = {
  buttonLabel: 'Finish Installation',
  mainTitle: 'Your password has been saved',
};

const mapStateToProps = (state) => ({
  qr: selectQR(state),
  isLoading: selectIsLoading(state),
  error: selectError(state),
});

const mapDispatchToProps = {
  finishInstallation: finishInstallationThunk,
};

export default connect(mapStateToProps, mapDispatchToProps)(SetupMFA);

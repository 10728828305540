import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import MobileFriendlyIcon from '@mui/icons-material/MobileFriendly';
import { SelectValidator, TextValidator } from 'react-material-ui-form-validator';
import ValidatorForm from '../common/ValidatorForm';
import ErrorSnackbar from '../common/ErrorSnackbar';
import Button from '../common/Button';

class LoginFormMFA extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyId: '',
      code: '',
    };
  }

  componentDidMount() {
    const { companies } = this.props;
    if (companies.length === 1) {
      this.setState({
        companyId: companies[0].id,
      });
    }
  }

  handleOnChange = event => {
    const { name, value } = event.target;
    this.setState({ [name]: value });
  };

  handleSubmit = () => this.props.onFormSubmit(this.state);

  render() {
    const {
      error,
      isLoading,
      companies,
      disabledMFA,
    } = this.props;
    const {
      companyId,
      code,
    } = this.state;
    return (
      <ValidatorForm
        className="loginForm mt-1"
        onSubmit={this.handleSubmit}
        instantValidate={false}
      >
        {
          companies.length > 1 &&
          <SelectValidator
            fullWidth
            className="m-medium"
            label="Company"
            onChange={this.handleOnChange}
            name="companyId"
            variant="standard"
            value={companyId.toString()}
            validators={['required']}
            errorMessages={['Company is required']}
            helperText="Select the company you log in with"
            data-tid="login-company-select"
          >
            {companies.map(({ id, companyName }) => (
              <MenuItem value={id.toString()} key={id} data-tid={`company-${companyName.replace(/\s+/g, '')}`}>{companyName}</MenuItem>
            ))}
          </SelectValidator>
        }
        {
          !disabledMFA && <TextValidator
            id="code"
            label="Authentication code"
            onChange={this.handleOnChange}
            name="code"
            type="code"
            value={code}
            margin="normal"
            fullWidth
            variant="standard"
            validators={['required']}
            errorMessages={['Authentication code is required']}
            helperText={
              <span className="d-flex">
                <MobileFriendlyIcon className="iconLeft iconSmall" />
                Open the two-factor authentication app on your device to view your authentication code and verify your identity.
              </span>
            }
          />
        }
        <ErrorSnackbar error={error} />
        <Grid container spacing={1} className="loginActions mt-1">
          <Grid item xs={12} md={12}>
            <Button
              type="submit"
              color="primary"
              isLoading={isLoading}
              className="loginButton"
            >
              Log in
            </Button>
          </Grid>
        </Grid>
      </ValidatorForm>
    );
  }
}

LoginFormMFA.propTypes = {
  onFormSubmit: PropTypes.func,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  companies: PropTypes.array,
  disabledMFA: PropTypes.bool,
};

export default LoginFormMFA;
import React, { Component, Fragment } from 'react';
import { styled } from '@mui/material/styles';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import {
  loginUserInit,
  loginUserMFA,
  loginGsuiteUser,
  startGoogleLogin,
} from '../store/thunks/authentication';
import Layout from '../components/layout/Layout';
import LoginFormInit from '../components/auth/LoginFormInit';
import LoginFormMFA from '../components/auth/LoginFormMFA';
import SetupMFA from '../components/auth/SetupMFA';
import { validateRoute } from '../utils/utils';

const PREFIX = 'login';

const classes = {
  paper: `${PREFIX}-paper`,
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    maxWidth: '400px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
  },
}));

class Login extends Component {
  static getInitialProps() {
    const page = {
      canViewAsAnonymus: true,
      canViewAsAuthenticated: false,
      title: 'Login',
    };
    return { page };
  }

  componentDidMount() {
    const { token, refreshToken, next } = this.props.router.query;
    const validatedNext = validateRoute(next);
    if (token && refreshToken) this.props.loginGsuiteUser({ token, refreshToken }, validatedNext);
  }

  handleSubmitLoginInit = (data) => {
    const { next } = this.props.router.query;
    const validatedNext = validateRoute(next);
    this.props.loginUserInit(data, validatedNext);
  };

  handleSubmitLoginMFA = (data) => {
    const { next } = this.props.router.query;
    const validatedNext = validateRoute(next);
    this.props.loginUserMFA(data, validatedNext);
  };

  handleGsuiteSubmit = () => this.props.startGoogleLogin();

  render() {
    const {
      companies,
      error,
      isLoading,
      verifyToken,
      mfaConfigureRequired,
      disabledMFA,
    } = this.props;

    return (
      <Root>
        {(!verifyToken || (verifyToken && !mfaConfigureRequired)) && (
          <Layout withTitle={false} withBreadcrumbs={false} withSliderMenu={false} data-tid="login">
            <div className="container">
              <Grid
                container
                spacing={2}
                className="mb-3"
                justifyContent="center"
              >
                <Grid item md={12}>
                  <Paper className={classes.paper} elevation={2}>
                    <Box sx={{ m: 3 }}>
                      <img
                        src="/assets/images/logos/bbfc-logo-b.png"
                        width={170}
                        height={146}
                      />
                    </Box>
                    <Divider className="my-2" style={{ width: '100%' }} />
                    {!verifyToken && (
                      <Fragment>
                        <Typography component="h1" variant="h5">
                          Log in
                        </Typography>
                        <LoginFormInit
                          error={error}
                          isLoading={isLoading}
                          onFormSubmit={this.handleSubmitLoginInit}
                          onGsuiteSubmit={this.handleGsuiteSubmit}
                        />
                      </Fragment>
                    )}
                    {verifyToken && !mfaConfigureRequired && (
                      <Fragment>
                        <Typography component="h1" variant="h5">
                          Log in
                        </Typography>
                        <LoginFormMFA
                          error={error}
                          isLoading={isLoading}
                          onFormSubmit={this.handleSubmitLoginMFA}
                          companies={companies}
                          disabledMFA={disabledMFA}
                        />
                      </Fragment>
                    )}
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </Layout>
        )}
        {verifyToken && mfaConfigureRequired && (
          <SetupMFA
            token={verifyToken}
            buttonLabel="Update"
            mainTitle="Please set up a new Authenticator"
          />
        )}
      </Root>
    );
  }
}

Login.propTypes = {
  router: PropTypes.object.isRequired,
  loginUserInit: PropTypes.func.isRequired,
  loginUserMFA: PropTypes.func.isRequired,
  loginGsuiteUser: PropTypes.func.isRequired,
  startGoogleLogin: PropTypes.func.isRequired,
  token: PropTypes.string,
  companies: PropTypes.array,
  error: PropTypes.string,
  isLoading: PropTypes.bool,
  verifyToken: PropTypes.string,
  mfaConfigureRequired: PropTypes.bool,
  disabledMFA: PropTypes.bool,
};

const mapDispatchToProps = {
  loginUserInit,
  loginGsuiteUser,
  startGoogleLogin,
  loginUserMFA,
};

const mapStateToProps = (state) => ({
  token: state.authentication.token,
  companies: state.authentication.companies,
  error: state.authentication.error,
  isLoading: state.authentication.loading,
  verifyToken: state.authentication.verifyToken,
  mfaConfigureRequired: state.authentication.mfaConfigureRequired,
  disabledMFA: state.authentication.disabledMFA,
});

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
